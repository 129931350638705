$brand-primary: #FF5A00;
$blue-dark: #263746;

$primary-hot: #F75903;
$primary-pale: #f4c9b9;

$blue: #148997;
$blue-light: #49A3AE;
$blue-ultralight: #EEFAFB;
$blue-bright: #26CAD3;
$blue-bright-light: #93e5e9;
$blue-pale: #C9F2F4;

$white: #FFF;
$black: #000;
$beige: #FBF8EE;

$txt-dark: #171717;

$gray: #E6E6E6;
$gray-light: #F9F9F9;
$gray-darker: #c4d1db;
$gray-dark: #808080;

$gray-blue: #F2F7FB;
// FONT
// Font basics
$body-font-size: 100%;
$line-height-default: 1.2;
$letter-spacing-default: normal;

// Font family
$font-family-base:        "FSElliot-Regular", sans-serif;
$font-family-heading:     "FSElliot", sans-serif;

// Font Weight
$font-weight-thin:        "FSElliot-Thin", sans-serif;
$font-weight-light:       "FSElliot-Light", sans-serif;
$font-weight-regular:     "FSElliot-Regular", sans-serif;
$font-weight-bold:        "FSElliot-Bold", sans-serif;
$font-weight-heavy:       "FSElliot-Heavy", sans-serif;


// LP CUSTOM


html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  * {
      outline: none;
      &:focus {
          outline: none;
      }
  }
}

h1,h2,h3,h4,h5 {
  font-weight: 800!important;
  font-family: $font-weight-heavy!important;
}

h1 {
  font-size: 42px;
}
h2 {
  font-size: 32px;
}
h3 {
  font-size: 26px;
}

ul li, a, p {
  font-family: $font-weight-regular;
}

ul {
  list-style-type: none;
}

button {
  text-align: center;
  min-width: 240px;
  transition: all ease-in-out .2s;
  &.btn-default {
    margin: 1rem auto;
  }
  &:hover {
    cursor: pointer;
  }
  span {
    width: 100%;
  }
}

.btn-default {
  border-radius: 7px!important;
  border: 2px solid $brand-primary;
  background-color: $brand-primary;
  box-shadow: 0 8px 23px 0 rgba(81,114,221,0.08);
  color: $white;
  padding: 1rem 2rem;
  display: inline-flex;
  margin-top: 1rem;
  font-family: $font-weight-bold;
  transition: all ease-in-out .2s;
  &:hover {
    color: $white;
    box-shadow: 2px 2px 16px 0 $primary-pale;
  }
}

.bg-white {
  background-color: $white;
}
.bg-gray {
  background-color: $gray-light;
}
.bg-beige {
  background-color: $beige;
}

.bold {
  font-family: $font-weight-bold;
}

.nav-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
}

label {
  a {
    color: $brand-primary;
    font-family: $font-weight-bold;
    &:hover {
      color: $brand-primary;
    }
  }
}

.grid-container {
  max-width: 85rem;
}

.title-label {
  color: $txt-dark;
  display: initial;
  font-size: 12px;
  font-weight: 800;
  border-radius: 4px;
  padding: .5rem 1rem;
  background-color: $blue-ultralight;
}

//H1
.main-title {
  color: $txt-dark;
  font-family: $font-weight-heavy;
  font-size: 48px;
  letter-spacing: -1.8px;
  line-height: 56px;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}

//H3
.main-description {
  font-size: 21px;
  //font-family: $font-weight-bold;
  letter-spacing: -0.3px;
  line-height: 28px;
}

.logo-pond {
  background-color: $white;
  padding: 2rem;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.08);
  position: relative;
  margin: -11rem 3rem 6rem;
  ul {
    display: flex;
    align-items: center;
    justify-content: space-around;
    li {
      display: inline-block;
    }
  }
}

.section-title {
  font-family: $font-weight-heavy;
  font-size: 48px;
  letter-spacing: -1.8px;
  line-height: 56px;
  margin-bottom: 1rem;
  &.no-sub {
    margin-bottom: 4rem;
  }
}

.section-subtitle {
  font-size: 21px;
  letter-spacing: -0.3px;
  line-height: 28px;
  text-align: center;
  margin-bottom: 1rem;
}

.txt-large {
  font-size: 21px;
  letter-spacing: -0.3px;
  line-height: 28px;
}

.bold {
  font-weight: bold;
}

.orange-bold {
  font-weight: bold;
  font-size: 25px;
  margin: 2rem 0 1rem;
  color: $brand-primary;
}
